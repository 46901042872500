import { takeLatest, put, call, select, all } from 'redux-saga/effects';
import { TasksService, ProduceService, DispatchersService } from '../../services';
import { LoaderActions } from '../loader';
import { types, default as TasksActions } from './actions';
import { showSuccess, showError } from '../../utils/notifications-helper';
import { getTranslate } from 'react-localize-redux';
import { push } from 'connected-react-router';
import { generateIdentifier } from '../../utils/generate-identifier-helper';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment-timezone';
import { get } from 'lodash';
import { calcRequestTime, allInfoData, someInfoData } from '../../utils/file-tools';
import { createPackagesCourse, updatePackagesCourse } from '../../utils/function-helper';
import XLSX from 'xlsx';

function* getStatisticsCoursesRequest() {
	let filterDevis = yield select((state) => state.tasks.filterDevis);
	let filterToTreat = yield select((state) => state.tasks.filterToTreat);
	const { current_user } = yield select((state) => state.user);
	let whereDevis = { _and: [{ devis: { _in: [1, 3] } }] };
	let whereToTreat = {
		_and: [{ team_id: { _is_null: true } }, { _or: [{ devis: { _eq: 0 } }, { devis: { _is_null: true } }] }],
	};

	const [error, result] = yield call(TasksService.getCoursesCounts, {
		whereDevis,
		whereToTreat,
	});
	if (result) {
		const { courses_devis, courses_to_treat } = result;
		filterDevis = {
			...filterDevis,
			pagination: {
				...filterDevis?.pagination,
				total: get(courses_devis, ['aggregate', 'count'], 0),
			},
		};
		filterToTreat = {
			...filterToTreat,
			pagination: {
				...filterToTreat?.pagination,
				total: get(courses_to_treat, ['aggregate', 'count'], 0),
			},
		};
		yield put(TasksActions.updateFiltersDevisReduc(filterDevis));
		yield put(TasksActions.updateFiltersToTreatReduc(filterToTreat));
		const basicWhere = {
			_or: [
				{ course: { merchant_id: { _is_null: true } } },
				{ course: { merchant: { users: { team_id: { _is_null: true } } } } },
				{ course: { created_by: { _eq: current_user.id } } },
			],
		};
		const week = {
			_and: [
				{ start_date: { _gte: moment().startOf('week') } },
				{ start_date: { _lte: moment().endOf('week') } },
			],
		};
		const day = {
			_and: [{ start_date: { _gte: moment().startOf('day') } }, { start_date: { _lte: moment().endOf('day') } }],
		};
		const [errorStats, resultStats] = yield call(TasksService.getAllCoursesCounts, {
			whereNotAttributeDay: {
				_and: [{ state: { _eq: 0 } }, basicWhere, day, { type: { _neq: 'collecte' } }],
			},
			whereAttributeDay: {
				_and: [{ state: { _eq: 1 } }, basicWhere, day, { type: { _neq: 'collecte' } }],
			},
			whereInProgressDay: {
				_and: [{ state: { _eq: 2 } }, basicWhere, day, { type: { _neq: 'collecte' } }],
			},
			whereValidateDay: {
				_and: [{ state: { _eq: 3 } }, basicWhere, day, { type: { _neq: 'collecte' } }],
			},
			whereErrorDay: {
				_and: [{ state: { _eq: 4 } }, basicWhere, day, { type: { _neq: 'collecte' } }],
			},
			whereNotAttributeWeek: {
				_and: [{ state: { _eq: 0 } }, basicWhere, week, { type: { _neq: 'collecte' } }],
			},
			whereAttributeWeek: {
				_and: [{ state: { _eq: 1 } }, basicWhere, week, { type: { _neq: 'collecte' } }],
			},
			whereInProgressWeek: {
				_and: [{ state: { _eq: 2 } }, basicWhere, week, { type: { _neq: 'collecte' } }],
			},
			whereValidateWeek: {
				_and: [{ state: { _eq: 3 } }, basicWhere, week, { type: { _neq: 'collecte' } }],
			},
			whereErrorWeek: {
				_and: [{ state: { _eq: 4 } }, basicWhere, week, { type: { _neq: 'collecte' } }],
			},
		});
		const tmp = {
			week: {
				0: get(resultStats, ['tasks_0_week', 'aggregate', 'count'], null) || null,
				1: get(resultStats, ['tasks_1_week', 'aggregate', 'count'], null) || null,
				2: get(resultStats, ['tasks_2_week', 'aggregate', 'count'], null) || null,
				3: get(resultStats, ['tasks_3_week', 'aggregate', 'count'], null) || null,
				4: get(resultStats, ['tasks_4_week', 'aggregate', 'count'], null) || null,
			},
			day: {
				0: get(resultStats, ['tasks_0_day', 'aggregate', 'count'], null) || null,
				1: get(resultStats, ['tasks_1_day', 'aggregate', 'count'], null) || null,
				2: get(resultStats, ['tasks_2_day', 'aggregate', 'count'], null) || null,
				3: get(resultStats, ['tasks_3_day', 'aggregate', 'count'], null) || null,
				4: get(resultStats, ['tasks_4_day', 'aggregate', 'count'], null) || null,
			},
		};
		yield put(TasksActions.getStatisticsCoursesSuccess(tmp));
	}
}

function* getCoursesRequest({ values, dispatch }) {
	const locale = yield select((state) => state.locale);
	const { current_user } = yield select((state) => state.user);
	const strings = getTranslate(locale);
	yield put(LoaderActions.loading());
	let and = [];
	if (values.state && values.state.length > 0) {
		and.push({ tasks: { state: { _in: values.state } } });
	}
	if (values.team_id) {
		and.push({ team_id: { _eq: values.team_id } });
	}
	if (values.merchant_id) {
		and.push({ merchant_id: { _eq: values.merchant_id } });
	}
	if (values.search) {
		and.push({
			_or: [
				{ tasks: { name: { _ilike: `"%${values.search}%"` } } },
				{ tasks: { company: { _ilike: `"%${values.search}%"` } } },
				{ merchant: { company: { _ilike: `"%${values.search}%"` } } },
				{ merchant: { users: { name: { _ilike: `"%${values.search}%"` } } } },
				{ identifier: { _ilike: `"%${values.search}%"` } },
			],
		});
	}
	if (values.start_at && values.end_at) {
		and.push({
			_and: [
				{ tasks: { start_date: { _gte: values.start_at } } },
				{ tasks: { start_date: { _lte: values.end_at } } },
			],
		});
	} else if (values.date && values.date.length === 2) {
		and.push({
			_and: [
				{ tasks: { start_date: { _gte: values.date[0] } } },
				{ tasks: { start_date: { _lte: values.date[1] } } },
			],
		});
	}
	and.push({
		_or: [
			{ devis: { _in: [0, 2, 4] } },
			{ created_by: { _eq: current_user.id }, devis: { _in: [0, 2, 4] } },
			{ order_id: { _is_null: false }, devis: { _in: [0, 2, 4] } },
		],
	});
	let filterUpdated = {
		...values,
	};

	const [error, result] = yield call(TasksService.getCoursesCount, {
		where: { _and: and },
	});
	if (result?.courses_aggregate?.aggregate?.count) {
		filterUpdated = {
			...filterUpdated,
			pagination: {
				...values?.pagination,
				total: result?.courses_aggregate?.aggregate?.count ?? 0,
			},
		};
	}
	if (!error) {
		yield put(TasksActions.updateFiltersReduc(filterUpdated));

		yield call(
			TasksService.getCourses,
			{
				where: { _and: and },
				limit: filterUpdated?.pagination?.pageSize ?? null,
				offset:
					!filterUpdated?.pagination?.current && !filterUpdated?.pagination?.current
						? null
						: String((filterUpdated?.pagination?.current - 1) * filterUpdated?.pagination?.pageSize ?? ''),
				updateQuery: (data) => {
					dispatch(TasksActions.getCoursesSuccess(data.courses));
					dispatch(LoaderActions.loaded());
				},
				errorQuery: (err) => {
					showError('get_tasks_error', strings, null, err);
					dispatch(LoaderActions.loaded());
					dispatch(TasksActions.getCoursesFailure());
				},
			},
			dispatch
		);
	}
}

function* getCoursesToTreatRequest({ values, dispatch }) {
	const locale = yield select((state) => state.locale);
	const strings = getTranslate(locale);
	yield put(LoaderActions.loading());
	let and = [{ team_id: { _is_null: true } }, { _or: [{ devis: { _eq: 0 } }, { devis: { _is_null: true } }] }];
	if (values.merchant_id) {
		and.push({ merchant_id: { _eq: values.merchant_id } });
	}
	if (values.search) {
		and.push({
			_or: [
				{ tasks: { name: { _ilike: `"%${values.search}%"` } } },
				{ tasks: { company: { _ilike: `"%${values.search}%"` } } },
				{ merchant: { company: { _ilike: `"%${values.search}%"` } } },
				{ merchant: { users: { name: { _ilike: `"%${values.search}%"` } } } },
				{ identifier: { _ilike: `"%${values.search}%"` } },
			],
		});
	}
	if (values.start_at && values.end_at) {
		and.push({
			_and: [
				{ tasks: { start_date: { _gte: values.start_at } } },
				{ tasks: { start_date: { _lte: values.end_at } } },
			],
		});
	} else if (values.date && values.date.length === 2) {
		and.push({
			_and: [
				{ tasks: { start_date: { _gte: values.date[0] } } },
				{ tasks: { start_date: { _lte: values.date[1] } } },
			],
		});
	}
	let filterUpdated = {
		...values,
	};

	const [error, result] = yield call(TasksService.getCoursesCount, {
		where: { _and: and },
	});
	if (result?.courses_aggregate?.aggregate?.count) {
		filterUpdated = {
			...filterUpdated,
			pagination: {
				...values?.pagination,
				total: result?.courses_aggregate?.aggregate?.count ?? 0,
			},
		};
	}
	if (!error) {
		yield put(TasksActions.updateFiltersToTreatReduc(filterUpdated));
		yield call(
			TasksService.getCourses,
			{
				where: { _and: and },
				limit: filterUpdated?.pagination?.pageSize ?? null,
				offset:
					!filterUpdated?.pagination?.current && !filterUpdated?.pagination?.current
						? null
						: String((filterUpdated?.pagination?.current - 1) * filterUpdated?.pagination?.pageSize ?? ''),
				updateQuery: (data) => {
					dispatch(TasksActions.getCoursesToTreatSuccess(data.courses));
					dispatch(LoaderActions.loaded());
				},
				errorQuery: (err) => {
					showError('get_tasks_error', strings, null, err);
					dispatch(LoaderActions.loaded());
					dispatch(TasksActions.getCoursesToTreatFailure());
				},
			},
			dispatch
		);
	}
}

function* getCoursesDevisRequest({ values, dispatch }) {
	const locale = yield select((state) => state.locale);
	const strings = getTranslate(locale);
	yield put(LoaderActions.loading());
	let and = [];
	if (values.merchant_id) {
		and.push({ merchant_id: { _eq: values.merchant_id } });
	}
	if (values.search) {
		and.push({
			_or: [
				{ merchant: { company: { _ilike: `"%${values.search}%"` } } },
				{ merchant: { users: { name: { _ilike: `"%${values.search}%"` } } } },
				{ identifier: { _ilike: `"%${values.search}%"` } },
			],
		});
	}
	if (values.start_at && values.end_at) {
		and.push({
			_and: [
				{ tasks: { start_date: { _gte: values.start_at } } },
				{ tasks: { start_date: { _lte: values.end_at } } },
			],
		});
	} else if (values.date && values.date.length === 2) {
		and.push({
			_and: [
				{ tasks: { start_date: { _gte: values.date[0] } } },
				{ tasks: { start_date: { _lte: values.date[1] } } },
			],
		});
	}
	and.push({ _and: [{ devis: { _in: [1, 3] } }] });

	let filterUpdated = {
		...values,
	};

	yield call(
		TasksService.getCourses,
		{
			where: { _and: and },
			limit: filterUpdated?.pagination?.pageSize ?? null,
			offset:
				!filterUpdated?.pagination?.current && !filterUpdated?.pagination?.current
					? null
					: String((filterUpdated?.pagination?.current - 1) * filterUpdated?.pagination?.pageSize ?? ''),
			updateQuery: (data) => {
				dispatch(TasksActions.getCoursesDevisSuccess(data.courses));
				dispatch(LoaderActions.loaded());
			},
			errorQuery: (err) => {
				showError('get_tasks_error', strings, null, err);
				dispatch(LoaderActions.loaded());
				dispatch(TasksActions.getCoursesDevisFailure());
			},
		},
		dispatch
	);
	const [error, result] = yield call(TasksService.getCoursesCount, {
		where: { _and: and },
	});
	if (!error && result?.courses_aggregate?.aggregate?.count) {
		filterUpdated = {
			...filterUpdated,
			pagination: {
				...values?.pagination,
				total: result?.courses_aggregate?.aggregate?.count ?? 0,
			},
		};
	}
	yield put(TasksActions.updateFiltersDevisReduc(filterUpdated));
}

function* getCourseRequest({ values, dispatch }) {
	yield put(LoaderActions.loading());
	const locale = yield select((state) => state.locale);
	const strings = getTranslate(locale);
	yield call(
		TasksService.getCourse,
		{
			id: values.id,
			updateQuery: (data) => {
				dispatch(TasksActions.getCourseSuccess(data.courses[0]));
			},
			errorQuery: (err) => {
				if (!err.loginError) {
					showError('get_tasks_error', strings, null, err);
				}
				dispatch(TasksActions.getCourseFailure());
			},
		},
		dispatch
	);
	yield put(LoaderActions.loaded());
}

function* createCourseRequest({ values, course, team, isImport, isNewTeam }) {
	const strings = yield select((state) => getTranslate(state.locale));
	const { current_user } = yield select((state) => state.user);
	const courses = yield select((state) => state.tasks.courses);
	yield put(LoaderActions.loading());

	const tasksTab = [];
	const devis = !team ? true : false;

	for (let i = 0, l = values.length; i < l; i++) {
		tasksTab.push({
			...values[i],
			uid: uuidv4(),
			state: 0,
		});
	}

	const res = yield generateIdentifier();

	const filter = {
		identifier: res,
		devis: devis ? 1 : 0,
		price: course.price || null,
		cost: course.cost || null,
		team_id: team ? team : null,
		package_id: course.type_colis ? course.type_colis : null,
		food: course.food ? course.food : null,
		order: devis
			? Object.values(courses).filter((v) => v.devis === 1).length + 2
			: Object.values(courses).filter((v) => v.devis !== 1).length + 2,
		fragile: course.fragile !== null ? course.fragile : null,
		merchant_id: course.merchant_id ? course.merchant_id : null,
		coursier_id: course.coursier_id ? course.coursier_id : null,
		created_by: current_user.id ? current_user.id : null,
		updated_by: null,
	};
	const [errorTask, responseTask] = yield call(TasksService.createCourse, {
		filter,
		tasksTab,
	});

	if (errorTask) {
		showError('create_task_error', strings, isImport > 0 ? 'Ligne ' + isImport : null, null, errorTask);
		yield put(TasksActions.createOrderFailure());
		yield put(LoaderActions.loaded());
		return;
	}

	const resultPackage = yield createPackagesCourse(tasksTab, responseTask.insert_courses_one, strings);
	if (resultPackage === false) {
		return;
	}

	if (isNewTeam) {
		const [errorDispatchers, responseDispatchers] = yield call(DispatchersService.dispatchersByTeamID, team);

		if (!errorDispatchers) {
			for (let i = 0, l = responseDispatchers.dispatchers.length; i < l; i++) {
				if (responseDispatchers.dispatchers[i].user.email) {
					yield call(ProduceService.sendEmail, {
						type: 'create-course-to-dispatcher-mail',
						toSend: {
							subject: 'Nouvelle course assignée',
							tasks: responseTask.insert_courses_one.tasks,
							email: responseDispatchers.dispatchers[i].user.email,
							link: `${process.env.REACT_APP_PLATFORM_URL}/#/course/${responseTask.insert_courses_one.id}`,
						},
					});
				}
			}
		}
	}

	yield put(TasksActions.createCourseSuccess(responseTask.insert_courses_one));

	if (!isImport) {
		showSuccess('create_task_success', strings);
	}

	const deliveryTasks = responseTask.insert_courses_one.tasks.filter(({ type }) => type === 'livraison');

	const deliveryTasksAndPackages = deliveryTasks.map((deliveryTask, i) => {
		const packages = [];

		resultPackage.forEach((pack) => {
			if (pack.delivery_id === deliveryTask.id) {
				packages.push(pack);
			}
		});

		deliveryTask.packagesByDeliveryId = packages;

		return deliveryTask;
	});

	if (deliveryTasksAndPackages) {
		for (const deliveryTask of deliveryTasksAndPackages) {
			yield generatePdfTaskRequest({ task: deliveryTask, isDymo: true, display: false });
		}
	}

	yield put(LoaderActions.loaded());
	yield put(push('/'));
}

function* modifyCourseRequest({ values, course, team, isImport, deleteByTable, isNewTeam }) {
	const start_date = moment();
	yield put(LoaderActions.loading());
	const strings = yield select((state) => getTranslate(state.locale));
	const { current_user } = yield select((state) => state.user);
	let tasksTab = [];
	let deleteTask = [];
	for (let i = 0, l = values.length; i < l; i++) {
		if (values[i] && values[i].delete) {
			deleteTask.push(values[i]);
		} else {
			tasksTab.push({
				...values[i],
				uid: values[i].uid && values[i].uid.length > 0 ? values[i].uid : uuidv4(),
				state: values[i].state !== undefined && values[i].state !== null ? values[i].state : 0,
			});
		}
	}

	if (deleteTask.length > 0) {
		let ids = [];
		for (let i = 0, l = deleteTask.length; i < l; i++) {
			if (deleteTask[i].id) {
				ids.push(deleteTask[i].id);
			}
		}
		let [newErrorDf] = yield call(TasksService.deleteTask, ids);
		if (newErrorDf) {
			showError('delete_tasks_error', strings, null, newErrorDf);
			return;
		}
	}

	let filter = {
		id: course.id,
		devis: course.devis === 1 && team ? 2 : course.devis,
		price: course.price || null,
		cost: course.cost || null,
		team_id: team ? team : null,
		food: course.food ? course.food : null,
		fragile: course.fragile !== null ? course.fragile : null,
		package_types_id: course.type_colis || null,
		merchant_id: course.merchant_id ? course.merchant_id : null,
		coursier_id: course.coursier_id ? course.coursier_id : null,
		created_by: course.created_by || null,
		updated_by: current_user.id ? current_user.id : null,
	};
	let res = {};
	if (tasksTab.length === 0 && course.devis !== 1) {
		let [errorDelete, responseDelete] = yield call(TasksService.deleteCourse, {
			id: course.id,
		});
		if (errorDelete) {
			showError('delete_course_error', strings, isImport > 0 ? 'Ligne ' + isImport : null, null, errorDelete);
			yield put(TasksActions.createCourseFailure());
			yield put(LoaderActions.loaded());
			return;
		}
		yield put(TasksActions.deleteCourseSuccess(responseDelete.delete_courses.returning[0]));
		if (!isImport) {
			showSuccess('delete_task_success', strings);
		}
	} else {
		/*let [errorTask, responseTask] = yield call(TasksService.modifyCourse, {
      filter,
      tasksTab,
    });*/
		const responses = yield all([
			call(TasksService.modifyCourse, filter),
			...tasksTab.map((t) => {
				if (t.key === t.id) return call(TasksService.updateTask, t);
				else return call(TasksService.createTask, { ...t, course_id: filter.id });
			}),
		]);
		let tasks = [];
		for (let i = 0, l = responses.length; i < l; i++) {
			const [error, response] = responses[i];
			if (response) {
				if (response?.insert_tasks_one) {
					tasks.push(response.insert_tasks_one);
				} else if (response?.update_tasks?.returning[0]) {
					tasks.push(response.update_tasks.returning[0]);
				} else if (response?.update_courses?.returning[0]) {
					res = response.update_courses.returning[0];
				}
			} else {
				showError('create_task_error', strings, isImport > 0 ? 'Ligne ' + isImport : null, null, error);
				yield put(TasksActions.createCourseFailure());
				yield put(LoaderActions.loaded());
				return;
			}
		}
		res = { ...res, tasks };
		yield put(TasksActions.modifyCourseSuccess(res));
		let resultPackage = yield updatePackagesCourse(tasksTab, res, strings);
		if (resultPackage === false) {
			return;
		}
		if (!isImport) {
			if (deleteByTable) showSuccess('delete_task_success', strings);
			else showSuccess('maj_course_success', strings);
		}
	}

	if (isNewTeam) {
		let [errorDispatchers, responseDispatchers] = yield call(DispatchersService.dispatchersByTeamID, team);
		if (!errorDispatchers) {
			for (let i = 0, l = responseDispatchers.dispatchers.length; i < l; i++) {
				if (responseDispatchers.dispatchers[i].user.email) {
					yield call(ProduceService.sendEmails, [
						{
							type: 'create-course-to-dispatcher-mail',
							toSend: {
								subject: 'Nouvelle course assignée',
								tasks: tasksTab,
								email: responseDispatchers.dispatchers[i].user.email,
								link: `${process.env.REACT_APP_PLATFORM_URL}/#/course/${res.id}`,
							},
						},
					]);
				}
			}
		}
	}
	yield put(LoaderActions.loaded());
	yield put(push('/'));
	calcRequestTime('updateCourse', start_date);
}

function* deleteTaskRequest({ values, deleteCourseId }) {
	const locale = yield select((state) => state.locale);
	const strings = getTranslate(locale);
	yield put(LoaderActions.loading());
	let ids = [];
	if (values.id) {
		ids.push(values.id);
	}

	let [newErrorDf] = yield call(TasksService.deleteTask, ids);
	if (newErrorDf) {
		showError('delete_tasks_error', strings, null, newErrorDf);
		yield put(LoaderActions.loaded());
		return;
	}
	if (deleteCourseId) {
		let [errorDelete] = yield call(TasksService.deleteCourse, {
			id: deleteCourseId,
		});
		if (errorDelete) {
			showError('delete_course_error', strings, null, errorDelete);
			yield put(TasksActions.createOrderFailure());
			yield put(LoaderActions.loaded());
		}
	}
	showSuccess('delete_task_success', strings);
	yield put(TasksActions.deleteTaskSuccess(values, deleteCourseId));
	yield put(LoaderActions.loaded());
}

function* updateOrderCoursesRequest({ values }) {
	const locale = yield select((state) => state.locale);
	const strings = getTranslate(locale);
	yield put(LoaderActions.loading());

	let mutation = `mutation {`;
	values.forEach((v, index) => {
		mutation =
			mutation +
			`
    update_${index + 1}: update_courses(where: {id: {_eq: ${v.id}}}, _set: {order_admin: ${v.order}}) {
      returning {
        id
      }
    }`;
	});
	mutation = mutation + `}`;

	let [error] = yield call(TasksService.modifyCourseOrder, {
		mutation,
	});
	if (error) {
		showError('modify_order_error', strings, null, error);
		yield put(TasksActions.updateOrderCoursesFailure());
		yield put(LoaderActions.loaded());
		return;
	}
	showSuccess('modify_order_success', strings);
	yield put(TasksActions.updateOrderCoursesSuccess(values));
	yield put(LoaderActions.loaded());
}

function* modifyTaskRequest({ values }) {
	const locale = yield select((state) => state.locale);
	const strings = getTranslate(locale);
	yield put(LoaderActions.loading());

	let [error, response] = yield call(TasksService.modifyTask, { values });
	if (error || !response.update_tasks.returning[0]) {
		showError('modify_task_error', strings, null, error);
		yield put(TasksActions.modifyTaskFailure());
		yield put(LoaderActions.loaded());
		return;
	}
	showSuccess('modify_task_success', strings);
	yield put(TasksActions.modifyTaskSuccess(response.update_tasks.returning[0]));
	yield put(LoaderActions.loaded());
}

function* modifyOnlyCourseRequest({ values, isNewTeam }) {
	const locale = yield select((state) => state.locale);
	const strings = getTranslate(locale);
	yield put(LoaderActions.loading());
	const filter = {};
	if ('team_id' in values) {
		filter.team_id = values.team_id;
	}
	if (values.devis !== undefined) {
		filter.devis = values.devis;
	}
	if (values.order !== undefined) {
		filter.order_admin = values.order;
	}
	if (values.price) {
		filter.price = values.price;
	}
	const [error, response] = yield call(TasksService.modifyOnlyCourse, {
		id: values.id,
		course: filter,
	});
	if (error) {
		showError('modify_task_error', strings, null, error);
		yield put(TasksActions.modifyCourseFailure());
		yield put(LoaderActions.loaded());
		return;
	}
	showSuccess('modify_task_success', strings);
	if (isNewTeam) {
		const [errorDispatchers, responseDispatchers] = yield call(
			DispatchersService.dispatchersByTeamID,
			values.team_id
		);
		if (!errorDispatchers) {
			for (let i = 0, l = responseDispatchers.dispatchers.length; i < l; i++) {
				if (responseDispatchers.dispatchers[i].user.email) {
					yield call(ProduceService.sendEmails, [
						{
							type: 'create-course-to-dispatcher-mail',
							toSend: {
								subject: 'Nouvelle course assignée',
								tasks: response.update_courses.returning[0].tasks,
								email: responseDispatchers.dispatchers[i].user.email,
								link: `${process.env.REACT_APP_PLATFORM_URL}/#/course/${response.update_courses.returning[0].id}`,
							},
						},
					]);
				}
			}
		}
	}
	yield put(TasksActions.modifyCourseSuccess(response.update_courses.returning[0]));
	yield put(TasksActions.getStatisticsCoursesRequest());
	yield put(LoaderActions.loaded());
}

function* generatePdfTaskRequest({ task, isDymo, display = true }) {
	yield put(LoaderActions.loading());
	const [errorKinds, responseKinds] = yield call(TasksService.getPackageKinds);
	let tmp = {};
	const package_kinds_dico = {
		frais: 'Frais',
		'no-alimentaire': 'Non alimentaire',
		sec: 'Sec',
		surgele: 'Surgelè',
	};
	yield responseKinds.package_kinds.forEach((v) => {
		tmp[v.id.toString()] = package_kinds_dico[v.kind];
	});
	task.packages_kinds_dico = tmp;
	task.packages = task.packagesByDeliveryId;
	const [error, response] = yield call(TasksService.generatePdfTask, {
		task,
		isDymo,
	});

	if (!display && !error && response && response.data) {
		yield put(TasksActions.generatePdfTaskSuccess(response.data.name));
	} else if (!error && !errorKinds && response && response.data) {
		let link = document.createElement('a');
		link.href = process.env.REACT_APP_FLYDRIVE_API_URL + '/tasks/' + response.data.name;
		link.download = response.data.name;
		link.target = '_blank';
		link.dispatchEvent(new MouseEvent('click'));
		yield put(TasksActions.generatePdfTaskSuccess(response.data.name));
	} else {
		const locale = yield select((state) => state.locale);
		const strings = getTranslate(locale);
		showError('generate_pdf_task_error', strings);
	}
	yield put(LoaderActions.loaded());
}

function* getMoreCourseRequest({ ids }) {
	yield put(LoaderActions.loading());
	const locale = yield select((state) => state.locale);
	const strings = getTranslate(locale);
	const [error, result] = yield call(TasksService.getMoreCourses, ids);
	if (error) {
		showError('get_tasks_error', strings);
		yield put(TasksActions.getMoreCoursesFailure());
	} else {
		yield put(TasksActions.getMoreCoursesSuccess(result.courses));
	}
	yield put(LoaderActions.loaded());
}

function* exportCourses({ filter, activeTab, total }) {
	yield put(LoaderActions.loading());
	const strings = yield select((state) => getTranslate(state.locale));
	let condition = null;
	switch (activeTab) {
		case '1':
			condition = {
				_and: [
					{ team_id: { _is_null: true } },
					{ _or: [{ devis: { _eq: 0 } }, { devis: { _is_null: true } }] },
				],
			};
			break;
		case '2':
			condition = { _and: [{ devis: { _in: [1, 3] } }] };
			break;
		case '3':
			condition = { _and: [{ team_id: { _is_null: false } }] };
			break;
		default:
			condition = { _and: [] };
			break;
	}
	if (filter.merchant_id) {
		condition = { ...condition, _and: [...condition['_and'], { merchant_id: { _eq: filter.merchant_id } }] };
	}
	if (filter.team_id) {
		condition = { ...condition, _and: [...condition['_and'], { team_id: { _eq: filter.team_id } }] };
	}
	if (filter.search) {
		condition = {
			...condition,
			_or: [
				{ tasks: { name: { _ilike: `%${filter.search}%` } } },
				{ tasks: { company: { _ilike: `%${filter.search}%` } } },
				{ merchant: { company: { _ilike: `%${filter.search}%` } } },
				{ merchant: { users: { name: { _ilike: `%${filter.search}%` } } } },
				{ identifier: { _ilike: `%${filter.search}%` } },
			],
		};
	}
	if (filter.start_at && filter.end_at) {
		condition = {
			...condition,
			_and: [
				...condition['_and'],
				{
					_and: [
						{ tasks: { start_date: { _gte: `${filter.start_at}` } } },
						{ tasks: { start_date: { _lte: `${filter.end_at}` } } },
					],
				},
			],
		};
	}

	if (Array.isArray(filter?.state) && filter.state.length > 0) {
		condition = { ...condition, _and: [...condition['_and'], { tasks: { state: { _in: filter.state } } }] };
	}
	if (filter.date && Array.isArray(filter.date) && filter.date.length > 0) {
		condition = {
			...condition,
			_and: [
				...condition['_and'],
				{
					_and: [
						{ tasks: { start_date: { _gte: `${filter.date[0].format()}` } } },
						{ tasks: { start_date: { _lte: `${filter.date[1].format()}` } } },
					],
				},
			],
		};
	}
	const [error, result] = yield call(TasksService.getCoursesForExport, {
		where: condition,
	});

	if (result?.courses) {
		let res_tasks = [];
		result.courses
			.sort((a, b) => moment(b.created_at).unix() - moment(a.created_at).unix())
			.forEach((course) => {
				course.tasks.forEach((task) => {
					if (task.type !== 'depot') {
						res_tasks.push(
							total
								? allInfoData({ ...task, merchant: course.merchant, team: course.team })
								: someInfoData({ ...task, merchant: course.merchant, team: course.team })
						);
					}
				});
			});
		const wb = XLSX.utils.book_new();
		const ws = XLSX.utils.json_to_sheet(res_tasks);
		XLSX.utils.book_append_sheet(wb, ws, 'DEKI');
		XLSX.writeFile(wb, `export_${moment().format('DD_MM_YYYY')}.xlsx`);
	} else {
		showError('get_invoices_for_export_error', strings);
	}
	yield put(LoaderActions.loaded());
}

const sagas = [
	takeLatest(types.GET_STATISTICS_COURSES_REQUEST, getStatisticsCoursesRequest),
	takeLatest(types.GET_COURSES_TO_TREAT_REQUEST, getCoursesToTreatRequest),
	takeLatest(types.GET_COURSES_REQUEST, getCoursesRequest),
	takeLatest(types.GET_COURSE_REQUEST, getCourseRequest),
	takeLatest(types.CREATE_COURSE_REQUEST, createCourseRequest),
	takeLatest(types.MODIFY_COURSE_REQUEST, modifyCourseRequest),
	takeLatest(types.DELETE_TASK_REQUEST, deleteTaskRequest),
	takeLatest(types.UPDATE_COURSES_ORDER_REQUEST, updateOrderCoursesRequest),
	takeLatest(types.MODIFY_TASK_REQUEST, modifyTaskRequest),
	takeLatest(types.MODIFY_ONLY_COURSE_REQUEST, modifyOnlyCourseRequest),
	takeLatest(types.GENERATE_PDF_TASK_REQUEST, generatePdfTaskRequest),
	takeLatest(types.GET_COURSES_DEVIS_REQUEST, getCoursesDevisRequest),
	takeLatest(types.GET_MORE_COURSES_REQUEST, getMoreCourseRequest),
	takeLatest(types.EXPORT_COURSES, exportCourses),
];
export default sagas;
