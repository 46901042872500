import { takeLatest, put, call, select } from 'redux-saga/effects';
import { DispatchersService, ProduceService } from '../../services';
import { LoaderActions } from '../loader';
import { types, default as DispatchersActions } from './actions';
import { default as AuthActions } from '../auth/actions';
import { showSuccess, showError} from  '../../utils/notifications-helper'
import { getTranslate } from 'react-localize-redux';

function* createDispatcherRequest({values, callback}) {
  yield put(LoaderActions.loading())
  const locale = yield select(state => state.locale);
  const teams = yield select(state => state.teams);
  const strings = getTranslate(locale)

  let team = teams.listByID[values.team_id]
  const [error, result] = yield call(DispatchersService.dispatchersCreate, values)
  if (result) {
    showSuccess("dispatcher_create_success", strings);
    let mailsToSend = [];
    if (values.email && values.email.length > 0) {
      mailsToSend.push({type: "create-dispatcher-to-dispatcher-mail", toSend: {subject: 'Nouveau compte dispatcheur', name: values.name, email: values.email, team: team ? team.name : "Aucune société de livraison assignée", link: "https://platform.deki.pro/"}});
    }
    if (team) {
      mailsToSend.push({type: "create-dispatcher-to-team-mail", toSend: {subject: 'Nouveau compte dispatcheur', name: values.name, email: team.email, team: team ? team.name : "Aucune société de livraison assignée"}})
    }
    yield call(ProduceService.sendEmails, mailsToSend)

    yield put(DispatchersActions.createDispatcherSuccess(result.data))
    if (callback) {callback()}
    yield put(LoaderActions.loaded())
  } else {
    showError("dispatcher_create_error", strings, null, error)
    yield put(DispatchersActions.createDispatcherFailure())
    yield put(LoaderActions.loaded())
  }
}

function* deleteDispatcherRequest({values}) {
  yield put(LoaderActions.loading())
  const locale = yield select(state => state.locale);
  const strings = getTranslate(locale)

  const [error, result] = yield call(DispatchersService.dispatchersDelete, values)
  if (result) {
    showSuccess("dispatcher_delete_success", strings)
    yield put(DispatchersActions.deleteDispatcherSuccess(result.delete_dispatchers.returning[0]))
    yield put(LoaderActions.loaded())
  } else {
    showError("dispatcher_delete_error", strings, null, error)
    yield put(DispatchersActions.deleteDispatcherFailure())
    yield put(LoaderActions.loaded())
  }
}

function* updateDispatcherRequest({values, record, callback}) {
  yield put(LoaderActions.loading())
  const locale = yield select(state => state.locale);
  const strings = getTranslate(locale)

  let tmp = {
    ...values,
    address: values.address ? values.address : null,
    phone_number: values.phone_number || "",
    name: values.name || "",
    team_id: values.team_id || "",
  } 

  if (values.password !== record.password && values.password.length > 0) {
    let data = {
      user: record.user,
      password: values.password
    }
    yield put(AuthActions.updatePassword(data))
  }

  const [error, result] = yield call(DispatchersService.dispatchersUpdate, {values: tmp, record})
  if (result) {
    showSuccess("dispatcher_update_success", strings)
    yield put(DispatchersActions.updateDispatcherSuccess(result.update_users.returning[0], record.id))
    if (callback) {callback()}
    yield put(LoaderActions.loaded())
  } else {
    showError("dispatcher_update_error", strings, null, error)
    yield put(DispatchersActions.updateDispatcherFailure())
    yield put(LoaderActions.loaded())
  }
}

function* getDispatchersRequest() {
  yield put(LoaderActions.loading())
  const locale = yield select(state => state.locale);
  const strings = getTranslate(locale)
  const [error, result] = yield call(DispatchersService.dispatchers)
  if (result) {
    yield put(DispatchersActions.getDispatchersSuccess(result.dispatchers))
    yield put(LoaderActions.loaded())
  } else {
    if (!error.loginError) {
      showError("get_dispatchers_error", strings, null, error)
    }
    yield put(DispatchersActions.getDispatchersFailure())
    yield put(LoaderActions.loaded())
  }
}

export default [
  takeLatest(types.DISPATCHERS_CREATE_REQUEST, createDispatcherRequest),
  takeLatest(types.DISPATCHERS_DELETE_REQUEST, deleteDispatcherRequest),
  takeLatest(types.DISPATCHERS_UPDATE_REQUEST, updateDispatcherRequest),
  takeLatest(types.GET_DISPATCHERS_REQUEST, getDispatchersRequest)
];
